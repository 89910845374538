<template lang="html">
  <div>
    <div class="col-sm-12 filter-group">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 filter-col">
          <el-select
            class="full-width"
            v-model="currentFilter"
            default-first-option
          >
            <el-option
              v-for="(item, index) of rules"
              :key="item.value"
              :label="item.name"
              :value="item"
              >
            </el-option>
          </el-select>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 filter-col">
          <el-select
            class="full-width"
            v-if="currentFilter"
            v-model="currentOpe"
            default-first-option
          >
            <el-option
              v-for="op of currentFilter.ops"
              :key="op.value"
              :label="op.name"
              :value="op"
              >
            </el-option>
          </el-select>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 filter-col">
          <el-select
            class="full-width"
            v-if="currentFilter.type === 'select'"
            v-model="selectedOption"
          >
            <el-option
              v-for="fv of filterValues"
              :key="fv.value"
              :label="fv.title"
              :value="fv"
              >
            </el-option>
          </el-select>

            <el-input
              class="full-width"
              v-if="currentFilter.type !== 'select'"
              v-model="inputValue"
              ref="saveTagInput"
            ></el-input>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 filter-col">
          <p-button class="full-width" @click="addFilter">
            <i class="fa fa-plus"></i>
             Thêm Điều kiện
          </p-button>
        </div>
      </div>
    </div>
    <div class="col-sm-12" v-if="tags.length > 0">
      <el-tag
        v-for="tag in tags"
        :key="tag.name + tag.ope + tag.value"
        closable @close="handleClose(tag)"
        :type="'filter'">
          <b>{{tag.name}}</b>
          {{tag.ope}}
          <span class="value">{{Number.isFinite(tag.value) ? tag.value : `"${tag.value}"`}}</span>
      </el-tag>
    </div>
  </div>
</template>

<script>
  import {Select, Option, Button, Tag, Input} from 'element-ui'

  export default {
    components: {
      ElSelect: Select,
      ElOption:Option,
      ElButton:Button,
      ElTag:Tag,
      ElInput:Input
    },
    props: ['rules'],
    data() {
      return {
        currentOpe: {},
        selectedOption: {},
        currentFilter: {},
        filterValues: [],
        tags: [],
        inputValue: '',
        output: []
      };
    },
    computed: {
      currentValue() {
        if (this.currentFilter.type === 'select') {
          return this.selectedOption.value
        }
        return this.inputValue
      },
      currentLabel() {
        if (this.currentFilter.type === 'select') {
          return this.selectedOption.title
        }
        return this.inputValue
      }
    },
    watch: {
      currentFilter(newVal, oldVal) {
        if (newVal.type === 'select') {
          this.filterValues = newVal.values;
        } else {
          this.filterValues = [];
        }
      }
    },
    methods: {
      handleClose(tag) {
        let index = this.tags.indexOf(tag);
        this.tags.splice(index, 1);
        this.output.splice(index, 1);
        this.$emit('filter-change', this.output);
      },
      addFilter() {
        let tag = {
          name: this.currentFilter.name,
          ope: this.currentOpe.name,
          value: this.currentLabel
        }
        tag.key = tag.name + tag.ope + tag.value;
        this.tags = this.tags.filter((x) => {
          return x.name != tag.name;
        });

        this.output = this.output.filter((x) => {
          return x.filter != this.currentFilter.value;
        });

        let res = {
          filter: this.currentFilter.value,
          ope: this.currentOpe.value,
          value: this.currentValue,
          type: this.currentFilter.type
        }
        this.output.push(res);
        this.tags.push(tag);
        this.$store.dispatch('setFilterTags', this.tags);
        this.$emit('filter-change', this.output);
      }
    },
    mounted() {
      if (this.$store.state.currentFilterUsers && this.$store.state.currentFilterUsers.length && this.$route.path.indexOf('users') > -1) {
        this.tags = this.$store.state.filterTags;
        this.$store.dispatch('setCurrentFilterOrders', []);
      } else if (this.$store.state.currentFilterOrders && this.$store.state.currentFilterOrders.length && this.$route.path.indexOf('orders') > -1) {
        this.tags = this.$store.state.filterTags;
        this.$store.dispatch('setCurrentFilterUsers', []);
      } else {
        this.$store.dispatch('setFilterTags', []);
        this.$store.dispatch('setCurrentFilterOrders', []);
        this.$store.dispatch('setCurrentFilterUsers', []);
      }
      this.currentFilter = this.rules[0];
    }
  }
</script>


<style lang="css">

  .filter-group{
    padding-top: 15px;
  }

  .filter-col{
    padding: 6px;
  }

  .filter-col:first-of-type{
    padding-left: 15px
  }

  .filter-col:last-of-type{
    padding-right: 15px
  }

  .el-tag--filter {
    background-color: #f8f9fa !important;
    color: #000 !important;
    margin: 0px;
    margin-right: 10px;
  }
  .el-tag--filter .value {
    color: #007bff !important;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag input {
    height: 40px;
    line-height: 40px;
  }
  button.full-width{
    margin: 2px;
  }
</style>
